/**
 * noAbsolute: Boolean true/false, when false we have grey bg and absolute position for loader
 * size: number, 70-300 default value is 226 (px)
 * logoSize: number 25-65 (px) default value is 65 (px)
 * logoQWSize: number 40-144 (px) default value is 144 (px)
 * logoAISize: number 70-218 (px) default value is 218 (px)
 * marginY: number 0-30 (px) default value is 30 (px)
 * compactMode: Boolean true/false, when enabled we are ignoring size, logoSize, marginY properties and giving fixed values
 * QW: Boolean true/false, when enabled we are using logos and colors for Quizzly.ai
 */
const Loader = ({
  noAbsolute,
  size,
  logoSize,
  marginY,
  logoAISize,
  logoQWSize,
  compactMode,
  QW,
}) => (
  <>
    {noAbsolute ? <></> : <div className="pos_loader-new-grey" />}
    <div
      className={`custom-loader-spinner new-vuukle-loader-container ${
        noAbsolute ? '' : 'pos_loader-new'
      }`}
    >
      <div
        className="new-vuukle-loader"
        style={{
          width: `${
            typeof size === 'number' && size > 69 && size < 301
              ? `${size}px`
              : '226px'
          }`,
        }}
      >
        {QW ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="144"
            height="144"
            viewBox="0 0 144 144"
            fill="none"
            style={{
              width: `${
                typeof logoQWSize === 'number' &&
                logoQWSize > 39 &&
                logoQWSize < 144
                  ? `${logoQWSize}px`
                  : '144px'
              }`,
            }}
          >
            <circle cx="72" cy="72" r="72" fill="#FACC2B" />
            <circle
              cx="72"
              cy="72"
              r="72"
              fill="url(#paint0_linear_1078_10671)"
            />
            <circle
              cx="72"
              cy="72"
              r="72"
              fill="url(#paint1_linear_1078_10671)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M72.0002 118.186C46.4483 118.186 25.7344 97.5077 25.7344 71.9997C25.7344 46.4918 46.4483 25.8135 72.0002 25.8135C97.5521 25.8135 118.266 46.4918 118.266 71.9997C118.266 83.5234 114.038 94.0613 107.047 102.152C105.811 103.583 105.8 105.741 107.138 107.077L115.341 115.266C116.42 116.343 115.656 118.186 114.129 118.186L72.0002 118.186ZM72.0002 97.6587C86.1957 97.6587 97.7035 86.1708 97.7035 71.9997C97.7035 57.8286 86.1957 46.3407 72.0002 46.3407C57.8047 46.3407 46.297 57.8286 46.297 71.9997C46.297 86.1708 57.8047 97.6587 72.0002 97.6587Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1078_10671"
                x1="16.0456"
                y1="28.8"
                x2="133.716"
                y2="45.5062"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E0FCFF" />
                <stop offset="1" stopColor="#C9CDE4" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1078_10671"
                x1="27.2084"
                y1="17.9698"
                x2="144.009"
                y2="143.967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#43E9F7" />
                <stop offset="1" stopColor="#7081DB" />
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="65"
            height="63"
            viewBox="0 0 65 63"
            fill="none"
            style={{
              width: `${
                typeof logoSize === 'number' && logoSize > 24 && logoSize < 66
                  ? logoSize
                  : 65
              }px`,
            }}
          >
            <g clipPath="url(#clip0_1080_10608)">
              <path
                d="M28.1334 9.6333C12.8633 9.6333 0.484375 20.7606 0.484375 34.487C0.484375 39.5457 2.16985 44.2485 5.05751 48.1738C4.67817 52.7089 3.58658 59.0969 0.484375 62.2086C0.484375 62.2086 9.96354 60.8746 16.3847 56.987C19.9514 58.4938 23.9318 59.3409 28.1334 59.3409C43.4033 59.3409 55.7823 48.2137 55.7823 34.487C55.7823 20.7606 43.4033 9.6333 28.1334 9.6333Z"
                fill="#FACC2B"
              />
              <path
                d="M28.1334 9.6333C12.8633 9.6333 0.484375 20.7606 0.484375 34.487C0.484375 39.5457 2.16985 44.2485 5.05751 48.1738C4.67817 52.7089 3.58658 59.0969 0.484375 62.2086C0.484375 62.2086 9.96354 60.8746 16.3847 56.987C19.9514 58.4938 23.9318 59.3409 28.1334 59.3409C43.4033 59.3409 55.7823 48.2137 55.7823 34.487C55.7823 20.7606 43.4033 9.6333 28.1334 9.6333Z"
                fill="#4885ED"
              />
              <path
                d="M28.1289 52.2411H28.7804L42.6475 20.2349H36.3189L31.0139 32.4587C29.8041 35.2115 28.594 39.6438 28.594 39.6438C28.594 39.6438 27.3378 35.1649 26.1277 32.4587L20.6833 20.2349H13.7031L28.1289 52.2411Z"
                fill="white"
              />
              <path
                d="M64.6235 24.9665C64.6235 11.2399 52.2445 0.112549 36.9746 0.112549C25.9483 0.112549 16.4315 5.91629 11.9922 14.3103C16.5344 11.3707 22.1029 9.63317 28.127 9.63317C43.3968 9.63317 55.7758 20.7605 55.7758 34.4871C55.7758 39.7375 53.96 44.6042 50.8688 48.617C57.0611 51.6165 64.6235 52.6878 64.6235 52.6878C61.5215 49.5773 60.4297 43.1893 60.0503 38.653C62.938 34.7276 64.6235 30.0249 64.6235 24.9665Z"
                fill="#FACC2B"
              />
            </g>
            <defs>
              <clipPath id="clip0_1080_10608">
                <rect
                  width="64.1654"
                  height="62.3615"
                  fill="white"
                  transform="translate(0.460938)"
                />
              </clipPath>
            </defs>
          </svg>
        )}
        <div
          className="new-loader-container"
          style={{
            width: `${
              typeof size === 'number' && size > 70 ? `${size}px` : '226px'
            }`,
            marginTop: `${
              typeof marginY && marginY > -1 && marginY < 30 ? marginY : 30
            }px`,
            marginBottom: `${
              typeof marginY && marginY > -1 && marginY < 30 ? marginY : 30
            }px`,
          }}
        >
          <div
            className="new-loader"
            style={{
              background: QW
                ? 'linear-gradient(98deg, #43e9f7 12.24%, #7081db 85.24%)'
                : '#4885ED',
            }}
          ></div>
        </div>
        {QW ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="218"
            height="73"
            viewBox="0 0 218 73"
            fill="none"
            style={{
              width: `${
                typeof logoAISize === 'number' &&
                logoAISize > 69 &&
                logoAISize < 218
                  ? `${logoAISize}px`
                  : '218px'
              }`,
            }}
          >
            <path
              d="M153.387 7.94751H147.342C147.3 7.94751 147.266 7.98185 147.266 8.0242V50.4835C147.266 50.5258 147.3 50.5602 147.342 50.5602H153.387C153.43 50.5602 153.464 50.5258 153.464 50.4835V8.0242C153.464 7.98185 153.43 7.94751 153.387 7.94751Z"
              fill="white"
            />
            <path
              d="M0 30.6572V29.1631C0.434483 23.0751 2.85355 18.1582 7.25718 14.4126C18.7064 4.67546 36.3827 10.1206 40.3898 24.5644C42.3848 31.7536 40.367 39.0764 35.2329 44.4761C35.174 44.5366 35.1751 44.5974 35.2357 44.6579L40.9789 50.3814C40.9934 50.3962 41.0033 50.4146 41.0073 50.4351C41.0113 50.4553 41.0093 50.4763 41.0014 50.4953C40.9934 50.5143 40.98 50.5308 40.9629 50.5425C40.9459 50.5538 40.9257 50.5601 40.9049 50.5604C34.9418 50.5547 29.0145 50.5567 23.1234 50.566C20.5222 50.5717 18.6287 50.4837 17.4428 50.3019C7.61577 48.8021 0.426893 40.6471 0 30.6572ZM29.9793 19.0766C27.5526 16.9747 24.7693 15.8243 21.6292 15.6255C9.55386 14.8585 1.73319 28.1945 8.71146 38.2611C11.9274 42.8996 16.4325 44.4903 21.9679 44.4619C26.2406 44.4411 30.5009 44.4468 34.7491 44.4789C34.7747 44.4789 34.8 44.4713 34.8214 44.4571C34.8427 44.4428 34.8595 44.4227 34.8695 44.3991C34.8794 44.3755 34.8823 44.3494 34.8777 44.3244C34.8729 44.2991 34.8609 44.2758 34.843 44.2574L30.9298 40.3517C30.8994 40.321 30.8817 40.2799 30.8806 40.237C30.8794 40.1941 30.8951 40.1529 30.9241 40.1216C36.7128 33.8158 36.468 24.7036 29.9793 19.0766Z"
              fill="white"
            />
            <path
              d="M53.7913 42.3939C54.2723 45.7002 57.9863 46.6973 60.7098 45.6207C62.8102 44.7913 64.2018 43.3674 64.8849 41.3487C65.1658 40.5212 65.308 39.2021 65.3117 37.3919C65.3194 32.9588 65.3154 28.5259 65.3004 24.0928C65.2984 23.9849 65.3524 23.9309 65.4626 23.9309H71.4391C71.5302 23.9309 71.5757 23.9754 71.5757 24.0644L71.5728 50.4722C71.5728 50.4955 71.5635 50.518 71.5469 50.5344C71.5304 50.5509 71.508 50.5603 71.4846 50.5603H66.5384C66.4971 50.5603 66.457 50.5458 66.4254 50.5194C66.3935 50.4927 66.3721 50.456 66.3647 50.4154L65.7984 47.0097C65.7736 46.8543 65.7093 46.8356 65.6049 46.9529C64.623 48.0493 63.7949 49.1429 62.3804 49.8843C59.6597 51.3073 56.2388 51.4522 53.2876 50.6966C48.5064 49.4695 47.2912 44.4448 47.2428 40.1727C47.1822 34.8062 47.1566 29.4406 47.166 24.0758C47.166 24.0373 47.1813 24.0005 47.2084 23.9733C47.2357 23.9462 47.2727 23.9309 47.3111 23.9309H53.2791C53.3701 23.9309 53.4157 23.9754 53.4157 24.0644C53.4347 28.5276 53.4527 32.9798 53.4697 37.4203C53.4774 39.3007 53.5844 40.9587 53.7913 42.3939Z"
              fill="white"
            />
            <path
              d="M86.7897 23.9309H80.1187C80.0746 23.9309 80.0391 23.9665 80.0391 24.0104V50.4779C80.0391 50.5219 80.0746 50.5574 80.1187 50.5574H86.7897C86.8338 50.5574 86.8693 50.5219 86.8693 50.4779V24.0104C86.8693 23.9665 86.8338 23.9309 86.7897 23.9309Z"
              fill="white"
            />
            <path
              d="M104.577 28.9614L93.9334 29.1403C93.831 29.1423 93.7797 29.092 93.7797 28.9898L93.7541 24.09C93.7541 23.9839 93.8073 23.9309 93.9135 23.9309H114.202C114.402 23.9309 114.449 24.0152 114.345 24.1837L101.119 45.6718C101.116 45.6775 101.114 45.6838 101.114 45.6906C101.113 45.6971 101.115 45.7037 101.118 45.7096C101.121 45.7153 101.126 45.7201 101.132 45.7235C101.138 45.7269 101.144 45.7287 101.151 45.7287L113.79 45.3594C113.915 45.3557 113.977 45.4171 113.977 45.544V50.3813C113.977 50.5006 113.919 50.5603 113.801 50.5603H91.5144C91.2847 50.5603 91.2306 50.4629 91.3521 50.2677C94.9836 44.4448 98.6443 38.5613 102.335 32.6171C103.077 31.4241 103.848 30.249 104.648 29.092C104.711 29.0031 104.687 28.9594 104.577 28.9614Z"
              fill="white"
            />
            <path
              d="M130.905 28.9585L120.358 29.129C120.138 29.1327 120.028 29.0247 120.028 28.8052V24.1439C120.028 24.0019 120.099 23.9309 120.241 23.9309H140.564C140.587 23.9309 140.609 23.9371 140.629 23.9487C140.649 23.9604 140.665 23.9771 140.676 23.9971C140.687 24.0171 140.693 24.0397 140.692 24.0625C140.691 24.0853 140.685 24.1076 140.672 24.1269L127.439 45.6406C127.403 45.6994 127.42 45.7278 127.49 45.7258L140.109 45.3651C140.132 45.3648 140.155 45.3688 140.177 45.3773C140.198 45.3855 140.218 45.398 140.234 45.4139C140.251 45.4296 140.265 45.4486 140.274 45.4693C140.283 45.4903 140.288 45.5128 140.288 45.5355L140.314 50.4126C140.314 50.5111 140.265 50.5603 140.169 50.5603H117.694C117.672 50.56 117.65 50.554 117.631 50.5427C117.612 50.5313 117.597 50.5151 117.586 50.4958C117.575 50.4765 117.57 50.4546 117.57 50.4325C117.571 50.4103 117.577 50.3887 117.589 50.37C121.723 43.7119 125.898 37.0434 130.114 30.3646C130.389 29.9291 130.673 29.4991 130.965 29.075C131.02 28.9955 131 28.9565 130.905 28.9585Z"
              fill="white"
            />
            <path
              d="M180.286 23.931H186.977C186.992 23.9309 187.006 23.9344 187.018 23.941C187.031 23.9476 187.042 23.9572 187.05 23.969C187.058 23.9807 187.063 23.9943 187.065 24.0084C187.067 24.0226 187.065 24.0369 187.06 24.0503L170.727 65.1461C170.721 65.1629 170.709 65.1771 170.694 65.1873C170.68 65.1976 170.662 65.203 170.644 65.203L164.341 65.1859C164.326 65.1856 164.312 65.1819 164.299 65.1748C164.287 65.168 164.276 65.1581 164.268 65.1459C164.26 65.1339 164.256 65.12 164.254 65.1058C164.253 65.0913 164.255 65.0771 164.261 65.0638L169.944 51.4011C169.949 51.3903 169.951 51.3787 169.951 51.367C169.951 51.3554 169.949 51.3438 169.944 51.333L158.959 24.0588C158.954 24.0454 158.952 24.0311 158.954 24.0169C158.955 24.0028 158.961 23.9893 158.969 23.9775C158.977 23.9658 158.988 23.9562 159 23.9495C159.013 23.9429 159.027 23.9395 159.041 23.9395L165.949 23.9253C165.967 23.9255 165.984 23.9313 165.999 23.942C166.014 23.9527 166.025 23.9677 166.031 23.9849L172.867 44.5073C172.873 44.5249 172.884 44.5406 172.899 44.5513C172.914 44.5624 172.932 44.5684 172.951 44.5684C172.97 44.5684 172.988 44.5624 173.003 44.5513C173.018 44.5406 173.029 44.5249 173.035 44.5073L180.204 23.9906C180.21 23.9734 180.221 23.9584 180.236 23.9477C180.251 23.937 180.268 23.9312 180.286 23.931Z"
              fill="white"
            />
            <path
              d="M204.345 48.4328C203.267 50.6143 199.931 51.154 197.797 50.6313C190.852 48.9242 190.667 38.0679 197.771 36.4347C200.298 35.8515 202.513 36.5719 204.416 38.5963C204.485 38.6681 204.52 38.6539 204.522 38.5537L204.561 36.7784C204.563 36.64 204.635 36.571 204.775 36.571H207.2C207.278 36.571 207.356 36.5866 207.428 36.6173C207.5 36.648 207.566 36.6929 207.621 36.7494C207.675 36.8059 207.717 36.873 207.745 36.9462C207.773 37.0195 207.786 37.0976 207.783 37.176C207.612 41.7321 207.874 45.9815 207.675 50.3842C207.671 50.5015 207.609 50.5603 207.49 50.5603H204.9C204.852 50.5603 204.804 50.5507 204.76 50.5319C204.716 50.5134 204.676 50.4859 204.643 50.4515C204.609 50.4172 204.583 50.3763 204.565 50.3314C204.548 50.2868 204.54 50.2391 204.542 50.1911L204.593 48.4953C204.594 48.4658 204.584 48.4365 204.566 48.4129C204.548 48.3893 204.523 48.3726 204.494 48.3652C204.465 48.3581 204.435 48.3607 204.408 48.3729C204.381 48.3854 204.359 48.4064 204.345 48.4328ZM200.188 48.2161C200.734 48.2067 201.273 48.0783 201.774 47.838C202.275 47.598 202.727 47.2509 203.106 46.8166C203.485 46.3823 203.782 45.8696 203.982 45.3074C204.181 44.745 204.278 44.1446 204.268 43.5401C204.246 42.319 203.788 41.1558 202.992 40.3059C202.197 39.4561 201.13 38.9897 200.027 39.0087C199.481 39.0184 198.942 39.1467 198.441 39.3871C197.94 39.6271 197.488 39.9742 197.109 40.4085C196.73 40.8428 196.433 41.3555 196.233 41.9176C196.034 42.48 195.937 43.0805 195.947 43.685C195.969 44.9061 196.427 46.0693 197.223 46.9191C198.018 47.7687 199.085 48.2354 200.188 48.2161Z"
              fill="white"
            />
            <path
              d="M187.343 50.87C188.543 50.87 189.515 49.8997 189.515 48.7027C189.515 47.5057 188.543 46.5354 187.343 46.5354C186.144 46.5354 185.172 47.5057 185.172 48.7027C185.172 49.8997 186.144 50.87 187.343 50.87Z"
              fill="white"
            />
            <path
              d="M214.048 33.9919C215.239 33.9919 216.205 33.0279 216.205 31.8389C216.205 30.6498 215.239 29.6858 214.048 29.6858C212.857 29.6858 211.891 30.6498 211.891 31.8389C211.891 33.0279 212.857 33.9919 214.048 33.9919Z"
              fill="white"
            />
            <path
              d="M83.4466 19.4543C85.3847 19.4543 86.9556 17.8862 86.9556 15.952C86.9556 14.0177 85.3847 12.4497 83.4466 12.4497C81.5085 12.4497 79.9375 14.0177 79.9375 15.952C79.9375 17.8862 81.5085 19.4543 83.4466 19.4543Z"
              fill="white"
            />
            <path
              d="M215.65 36.5938H212.531C212.449 36.5938 212.383 36.6599 212.383 36.7415V50.4098C212.383 50.4913 212.449 50.5575 212.531 50.5575H215.65C215.732 50.5575 215.798 50.4913 215.798 50.4098V36.7415C215.798 36.6599 215.732 36.5938 215.65 36.5938Z"
              fill="white"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
    </div>
  </>
);

export default Loader;
